/* ./src/index.css */
@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,500;1,600;1,700;1,800;1,900&display=swap');

body {
  font-family: 'Poppins', 'sans-serif' !important;
}

.thin-scrollbar::-webkit-scrollbar-track {
  border-radius: 10px;
  opacity: 0.3;
  background-color: #d8d8d88e;
}

.thin-scrollbar::-webkit-scrollbar {
  width: 8px;
  opacity: 0.3;
  background-color: #d8d8d88e;
}

.thin-scrollbar::-webkit-scrollbar-thumb {
  border-radius: 10px;
  opacity: 1;
  background-color: #c4c4c4;
}

.react-select__control {
  padding: 3.5px;
  border-radius: 8px !important;
}

:root {
  --toastify-color-success: #3498db !important;
}
